<template>
  <div class="channel_content">
    <div class="channel_search fixed">
      <van-search
        v-model="searchValue"
        placeholder="请输入活码名称"
        shape="round"
        @search="onSearch"
      />
    </div>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onDownRefresh"
      loading-text=""
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="!isShowEmpty"
      >
        <div class="codeList_content">
          <div
            class="code_content"
            @click="handleCodeJump(item)"
            v-for="(item, index) in codeList"
            :key="index"
          >
            <img :src="item.qr_code" alt="二维码" class="code_img" />
            <div class="code_tipContent">
              <div class="code_title">{{ item.card_name }}</div>
              <div>累计添加客户数：{{ item.total_add_count }}</div>
              <div class="code_name_list">
                使用员工：
                <template v-for="(use_item, use_index) in item.use_customer">
                  <span :key="use_index"
                    >{{ use_item.name || use_item.NAME
                    }}<span v-if="use_index != item.use_customer.length - 1"
                      >、</span
                    ></span
                  >
                </template>
              </div>
              <div class="code_create">
                {{ item.create_at }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-empty description="没有数据" v-if="list == null" />
    <div class="emptyMsg" v-if="isShowEmpty && !isSearchState">
      <img src="@/assets/images/empty.png" alt="" class="img" />
      <div class="text text1">暂无渠道活码</div>
      <div class="text text2">您可以前往电脑端管理台创建~</div>
    </div>
    <empty-page
      :msg="emptyMsg"
      v-if="isShowEmpty && isSearchState"
    ></empty-page>
  </div>
</template>

<script>
import emptyPage from "@/components/emptyPage.vue";
export default {
  name: "channelLiveCode",
  components: { emptyPage },
  data() {
    return {
      codeList: [],
      searchValue: "",
      curState: "",
      list: [],
      page: 0, //页码
      loading: false, //上拉加载
      finished: false, //上拉加载完毕
      refreshing: false, //下拉刷新
      isDown: false,
      isShowEmpty: false,
      isSearchState: false,
    };
  },
  created() {
    this.curState = this.$route.query.type;
  },
  activated() {
    this.isSearchState = false;
  },
  computed: {
    emptyMsg() {
      return this.search_value ? "搜索无结果" : "暂无活码";
    },
  },
  mounted() {},
  //监听
  watch: {
    $route(to, from) {
      if (from.name === "home") {
        this.codeList = [];
        this.loading = false; //上拉加载
        this.refreshing = true; //下拉刷新
        this.isDown = true;
        this.refreshing = true;
        this.onDownRefresh();
      }
    },
  },
  methods: {
    //上拉加载
    onLoad() {
      if (this.refreshing) {
        this.codeList = [];
        this.refreshing = false;
      }
      if (this.isDown) {
        this.page = 1;
      } else {
        this.page++;
      }
      this.getChannelCodeListData();
    },
    getChannelCodeListData() {
      this.$ajax("card_list", {
        name: this.searchValue || "",
        start_at: "",
        end_at: "",
        page: this.page,
        page_size: 10,
      }).then((res) => {
        if (res.data && res.data.code === 0) {
          let resCodeList = res.data.data.list;
          if (resCodeList.length > 0) {
            if (this.isDown) {
              this.codeList = resCodeList;
              this.loading = false;
              this.isDown = false;
            } else {
              this.codeList = this.codeList.concat(resCodeList);
              this.loading = false;
            }
          } else {
            this.finished = true; //完成加载
          }
          this.$toast.clear();
          this.isShowEmpty = !this.codeList.length ? true : false;
        } else {
          this.$toast("网络错误");
        }
      });
    },
    // 下拉刷新
    onDownRefresh() {
      this.finished = false;
      this.loading = true;
      this.isDown = true;
      this.onLoad();
    },
    setLoading() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
    },
    handleCodeJump(item) {
      this.$router.push({
        name: "codeDetails",
        query: {
          type: this.curState,
          code_url: item.qr_code,
          use_customer: JSON.stringify(item.use_customer),
          name: item.card_name,
        },
      });
    },
    onSearch() {
      //搜索重置参数
      this.setLoading();
      console.log(this.searchValue);
      this.isDown = true;
      this.isSearchState = true;
      this.codeList = [];
      this.page = 1;
      this.refreshing = false;
      this.finished = false;
      this.getChannelCodeListData();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "home" || to.name === "codeDetails") {
      this.searchValue = "";
      this.isShowEmpty = false;
    }
    next();
  },
};
</script>
<style lang="scss" scoped>
.channel_content {
  height: 100%;
  width: 100%;
  .emptyPage {
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .channel_search {
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 9999;
  }
  .channel_tip {
    height: 0.8rem;
    text-align: center;
    line-height: 0.8rem;
  }
  .codeList_content {
    padding: 0.24rem 0.32rem 0;
    .code_content {
      background: #ffffff;
      border-radius: 0.16rem;
      display: flex;
      align-items: center;
      margin-top: 0.16rem;
      padding: 0.24rem 0.32rem;
      .code_img {
        flex-shrink: 0;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 0.08rem;
      }
      .code_tipContent {
        margin-left: 0.32rem;
        font-size: 0.24rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.36rem;
        overflow: hidden;
        .code_title {
          margin-bottom: 0.04rem;
          font-size: 0.28rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.4rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .code_name_list {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .code_create {
          color: #999999;
        }
        .code_state {
          height: 0.48rem;
          width: 100%;
          line-height: 0.24rem;
          span {
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            padding: 0 0.13rem;
            height: 0.48rem;
            text-align: center;
            line-height: 0.48rem;
            position: absolute;
            top: 0;
            right: 0;
            border-bottom-left-radius: 25%;
          }
        }
      }
    }
  }
  .emptyMsg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      width: 1.76rem;
      height: 1.76rem;
    }
    .text {
      text-align: center;
      font-size: 0.32rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.48rem;
      white-space: nowrap;
    }
    .text1 {
      margin-top: 0.32rem;
    }
    .text2 {
      margin-top: 0.12rem;
    }
  }
}
</style>
<style lang="scss">
.channel_content {
  .van-list {
    background: #f5f6f9;
  }
}
</style>
